import { useState, useEffect } from 'react'
import { request } from '../lib/axisClient'
import { Story } from '../types/story'

export const useStories = () => {
	const [stories, setStories] = useState<Story[]>([])
	const [totalPage, setTotalPage] = useState(0)

	const limit = 10;

	const fetchStories = async (page = 1) => {
		const offset = page === 1 ? 0 : (page - 1) * limit
		try {
			const res = await request({
				url: `/stories?limit=${limit}&offset=${offset}`,
				method: 'GET'
			})
			setTotalPage(Math.ceil(res.totalCount / limit))
			setStories(res.contents)
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		fetchStories()
	}, [])

	return { stories, fetchStories, totalPage }
}
