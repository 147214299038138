import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Layout } from '../../components/layout'
import { useStories } from '../../hooks/useStories'
import { Stories } from '../../components/stories'
import { Article } from '../../components/article'
import { Story as StoryType } from '../../types/story'
import { SEO } from '../../components/seo'

const StoryPage = ({ params }: any) => {
	const { stories } = useStories()
	const [story, setStory] = useState<StoryType | null>(null)

	useEffect(() => {
		const story = stories.find(story => story.title === params._title)
		if (story) {
			setStory(story)
		}
	}, [params, stories])

	return (
		<Layout>
			<SEO
				title={`${params._title} > ${story && story.categories[0].title} | RUBIA ルビア 渋谷メキシカン【朝食・ランチ・バー】`}
				description={story && story.content.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'')}
				keywords={['メキシコ料理', '渋谷' , 'ファインメキシカン', 'ルビア', 'sarasa']}
			/>
			<h1 className="h1">{params._title} | RUBIA ルビア / DJ sarasa</h1>
			<div className="story">
				{story && (
					<>
						<Article type="story" article={story} />
						<Stories />
					</>
				)}
			</div>
		</Layout>
	)
}

export default StoryPage
