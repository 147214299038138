import React from 'react'
import { Link } from 'gatsby'
import Slider from 'react-slick'
import { ScrollAndShow } from '../components/scroll-and-show'
import { useStories } from '../hooks/useStories'

export const Stories = () => {
	const { stories } = useStories()

	return (
		<ScrollAndShow delay={200}>
			<div className="pt-8 pb-8 md:pb-16">
				<div className="stories-text font-lato text-center md:mb-10 mb-5">
					<Link to="/stories">STORIES</Link>
				</div>
				<div className="slider-wrapper">
					<Slider {...{
						dots: false,
						arrows: false,
						infinite: true,
						autoplay: true,
						autoplaySpeed: 3000,
						speed: 500,
						slidesToShow: 1,
						slidesToScroll: 1,
						variableWidth: true
					}} className="md:pl-11 pl-3.5">
						{stories.map((story, index) => (
							<Link key={index} to={`/stories/${story.title}`}>
								<div className="md:mr-10 mr-3.5">
									<img className="story-image" src={story.coverImage.url} alt={story.title} />
									<div className="vol font-lato">Vol.{story.vol}</div>
									<div className="title font-yu">{story.title}</div>
								</div>
							</Link>
						))}
					</Slider>
				</div>
			</div>
		</ScrollAndShow>
	)
}
