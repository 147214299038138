import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { useTranslation, Trans } from 'react-i18next'
import { Tag as TagType } from '../types/tag'
import { Category as CategoryType } from '../types/category'

export const Tags = (params: { tags: TagType[], categories: CategoryType[] }) => {
	const [t, i18n] = useTranslation()

	const { tags, categories } = params

	return (
		<>
			{tags.length > 0 && (
				<div className="tags-wrapper">
					<div className="tags-text font-lato">TAGS</div>
					<div className="tags-list">
						{tags.map((tag, index) => (
							<Link key={index} to={`/tags/${tag.title}`}>
								<div className={`tag-title ${i18n.language === 'en' && tag.titleEn ? 'font-lato' : 'font-yu'}`}>
									#{i18n.language === 'en' && tag.titleEn ? tag.titleEn : tag.title}
								</div>
							</Link>
						))}
					</div>
				</div>
			)}
	
			{categories.length > 0 && (
				<div className="category-wrapper">
					<div className="category-text font-lato">CATEGORY</div>
					<div className="categories-list">
						{categories.map((category, index) => (
							<Link key={index} to={`/categories/${category.title}`}>
								<div className={`category-title ${i18n.language === 'en' && category.titleEn ? 'font-lato' : 'font-yu'}`}>
									{i18n.language === 'en' && category.titleEn ? category.titleEn : category.title}
								</div>
							</Link>
						))}
					</div>
				</div>
			)}
		</>
	)
}
