import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Story as StoryType } from "../types/story";
import { ScrollAndShow } from "../components/scroll-and-show";
import { EventReport as EventReportType } from "../types/event-report";
import { Category as CategoryType } from "../types/category";
import { Tag as TagType } from "../types/tag";
import { Tags } from "./tags";
import { useStories } from "../hooks/useStories";
import { useEventReports } from "../hooks/useEventReports";

const TITLE = {
	story: "STORY",
	eventReport: "EVENT",
};

export const Article = (params: {
	type: "story" | "eventReport";
	article: StoryType | EventReportType;
	category?: CategoryType;
	tag?: TagType;
}) => {
	const [t, i18n] = useTranslation();

	const { type, article, category, tag } = params;
	const [currentIndex, setCurrentIndex] = useState(0);
	const [articles, setArticles] = useState([]);

	const { stories } = useStories();
	const { eventReports } = useEventReports();

	useEffect(() => {
		if (type === "story") {
			setArticles(stories);
		} else if (type === "eventReport") {
			setArticles(eventReports);
		}
		if (articles.length > 0) {
			const articleId = article.id;
			const index = articles.findIndex((article) => article.id === articleId);
			setCurrentIndex(index);
		}
	}, [article, articles, stories, eventReports]);

	return (
		<div className="article">
			<ScrollAndShow>
				<img
					className="article-image"
					src={article.coverImage.url}
					alt={article.title}
				/>
			</ScrollAndShow>
			<div className="inner">
				<div className="breadcrumb font-yu">
					<ScrollAndShow>
						<Link to="/">HOME</Link>
						{" > "}
						{type === "story" ? (
							<Link to="/stories">STORY</Link>
						) : (
							<Link to="/event_reports">EVENT</Link>
						)}
						{" > "}
						{category ? (
							<>
								<Link
									to={`/categories/${
										(i18n.language === "en" && category.titleEn) ||
										category.title
									}`}
								>
									{(i18n.language === "en" && category.titleEn) ||
										category.title}
								</Link>
								{` > `}
							</>
						) : tag ? (
							<>
								<Link
									to={`/tags/${
										(i18n.language === "en" && tag.titleEn) || tag.title
									}`}
								>
									{(i18n.language === "en" && tag.titleEn) || tag.title}
								</Link>
								{` > `}
							</>
						) : (
							<></>
						)}
						{article.vol && `Vol.${article.vol}`}{" "}
						{(i18n.language === "en" && article.titleEn) || article.title}
					</ScrollAndShow>
				</div>
				<ScrollAndShow>
					<div className="title-wrapper">
						<div className="article-text font-lato">{TITLE[type]}</div>
						<div
							className={`divider ${type === "eventReport" && "event-report"}`}
						/>
						<div>
							<div className="title font-yu">
								{article.vol && `Vol.${article.vol}`}{" "}
								{(i18n.language === "en" && article.titleEn) || article.title}
							</div>
							<div className="date font-yu">
								{article.date && format(new Date(article.date), "yyyy.MM.dd")}
							</div>
						</div>
					</div>
				</ScrollAndShow>
				<ScrollAndShow>
					<div
						className="content"
						dangerouslySetInnerHTML={{
							__html:
								(i18n.language === "en" && article.contentEn) ||
								article.content,
						}}
					/>
				</ScrollAndShow>

				<ScrollAndShow>
					<Tags tags={article.tags} categories={article.categories} />
				</ScrollAndShow>
			</div>
			<ScrollAndShow>
				<div className="pagination-buttons font-lato">
					{articles[currentIndex - 1] ? (
						<Link
							to={`/${type === "story" ? "stories" : "event_reports"}/${
								articles[currentIndex - 1].title
							}`}
						>
							← PREV
						</Link>
					) : (
						<a className="disabled">← PREV</a>
					)}
					{articles[currentIndex + 1] ? (
						<Link
							to={`/${type === "story" ? "stories" : "event_reports"}/${
								articles[currentIndex + 1].title
							}`}
						>
							NEXT →
						</Link>
					) : (
						<a className="disabled">NEXT →</a>
					)}
				</div>
			</ScrollAndShow>
		</div>
	);
};
